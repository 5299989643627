#root {
  background-color: #f4efea;
}

.paragraph-header {
  font-family: "The Girl Next Door", system-ui;
  font-weight: 600;
  font-style: normal;
}

p {
  margin-bottom: 2.5rem;
}

.paragraph-text {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}

.button-text {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

img {
  max-width: 70%;
  height: auto;
}
